@import '@styles/colors.sass'
@import '@styles/mixins.sass'

.container
    min-width: 600px
    background-color: var(--primary-color)
    padding: 30px 50px
    .formContainerTop
        margin-bottom: 40px
        @include for-small-desktop
            margin-bottom: 30px
        @include for-phone
            margin-bottom: 20px
    @include for-small-desktop
        min-width: 540px
        padding: 25px 45px
    @include for-tablet
        padding: 25px 40px
    @include for-tablet-portrait
        min-width: 500px
    @include for-phone
        min-width: auto
        width: 100%
        padding: 20px 20px

.headerContainer
    margin-bottom: 40px
    @include for-small-desktop
        margin-bottom: 30px
    @include for-phone
        margin-bottom: 20px

.rtkStatusError
    color: $red
    font: var(--font-md18)
    margin-bottom: 8px

.codeStatusContainer
    margin: 18px 0 40px
    display: flex
    align-items: center
    justify-content: flex-start
    @include for-tablet
        margin: 15px 0 30px
    @include for-phone
        margin: 10px 0 20px
    p
        font: var(--font-md20)
        line-height: 25px
        letter-spacing: -0.01em
        color: $green
        @include for-tablet
            font: var(--font-md18)
            line-height: 23px
        @include for-phone
            font: var(--font-md16)
            line-height: 20px

.iconContainer
    margin-right: 15px
    @include for-tablet
        margin-right: 10px
    @include for-phone
        margin-right: 5px
    div
        display: flex
        align-items: center
        justify-content: center
        svg
            width: 25px
            height: 25px
            @include for-tablet
                width: 22px
                height: 22px
            @include for-phone
                width: 20px
                height: 20px

.confirmFormContainer
    margin-bottom: 40px
    @include for-tablet
        margin-bottom: 30px
    @include for-phone
        margin-bottom: 20px
    form
        display: flex
        align-items: center
        justify-content: space-between

.inputContainer
    width: 60%
    margin-right: 15px
    @include for-phone
        margin-right: 10px

.btnContainer
    width: 40%

.goToLogin
    cursor: pointer
    margin-top: 15px
    span
        font: var(--font-md18)
        line-height: 25px
        letter-spacing: -0.01em
        text-decoration-line: underline
        color: var(--accent-color-2)
        @include for-phone
            font: var(--font-md16)
            line-height: 20px
