@import '@styles/colors.sass'
@import '@styles/mixins.sass'

.inputContainer
    position: relative
    width: 100%


.input
    position: relative !important
    border: 1px solid var(--secondary-color) !important
    width: 100% !important
    font: var(--font-md18) !important
    border: 0 !important
    outline: 0 !important
    background-color: var(--secondary-color) !important
    padding: 11px 15px
    font-weight: 400 !important
    color: var(--text-color) !important
    @include for-phone
        font: var(--font-md17)
        padding: 10px
    &::placeholder
        font: var(--font-md18)
        font-weight: 400
        color: var(--primary-light-color)
        @include for-phone
            font: var(--font-md17)


.thin
    @extend .input
    font: var(--font-md19)
    padding: 7px 12px
    @include for-small-desktop
        font: var(--font-md18)
        padding: 5px 10px
    &::placeholder
        font: var(--font-md19)
        @include for-small-desktop
            font: var(--font-md18)

.invalid
    @extend .input
    border: 1px solid $red
    margin-bottom: 1px !important

.errorMessage
    p
        color: $red
        font: var(--font-xs)
        margin-bottom: 5px !important

.eyeContainer
    position: absolute
    right: 8px
    top: 8px
    width: 26px
    height: 26px
    cursor: pointer
    @include for-phone  
        width: 24px
        height: 24px
    div
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: 100%
    svg
        width: 100%
        height: 100%
        path
            fill: var(--primary-light-color)
            transition: fill 0.3s ease-in-out
    &:hover
        svg
            path
                fill: var(--text-color)
