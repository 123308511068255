@import '@styles/mixins.sass'

.topContainer
    display: flex
    justify-content: space-between

.contentContainer
    p
        margin-top: 10px
        color: var(--text-color)
        font: var(--font-md18)

.accentTextContainer
    margin-top: 35px
    p
        color: var(--accent-color)
        font: var(--font-md22)
        line-height: 28px
        max-width: 550px
        @include for-small-desktop
            font: var(--font-md19)
            line-height: 24px
            max-width: 450px
        

.linksContainer
    margin-top: 10px
    display: flex
    @include for-tablet-portrait
        flex-direction: column

.linkItemContainer
    margin-right: 10px
    @include for-tablet-portrait
        margin-right: 0
        margin-bottom: 15px
    &:last-child
        margin-right: 0
        @include for-tablet-portrait
            margin-bottom: 0

    .contactBtn
        font: var(--font-sm)
        display: flex
        align-items: center
        gap: 8px
        justify-content: center
        background: transparent
        box-shadow: none
        border: 1px solid
        border-image: linear-gradient(119.52deg, #2D74D5 0%, #7989D4 100%)
        border-image-slice: 1
        transition: all 2s

        span
            color: var(--text-color)
            font: var(--font-sm)


        .icon
            width: 16px
            height: 16px

        &:hover
            background: linear-gradient(119.52deg, #2D74D5 0%, #7989D4 100%)
    a
        text-decoration: none
        display: flex
        align-items: center
        justify-content: flex-start
        div
            display: flex
            align-items: center
            justify-content: center
            margin-right: 2px
            svg
                width: 16px
                height: 16px
        p
            color: var(--text-color)
            transition: .3s
            margin-top: 0
        &:hover
            p
                color: var(--accent-color)

.linkTextItemContainer
    margin-right: 40px
    @include for-tablet-portrait
        margin-right: 0
        margin-bottom: 15px
    &:last-child
        margin-right: 0
        @include for-tablet-portrait
            margin-bottom: 0
    display: flex
    align-items: center
    justify-content: flex-start
    div
        display: flex
        align-items: center
        justify-content: center
        margin-right: 2px
        svg
            width: 16px
            height: 16px
    p
        color: var(--text-color)
        transition: .3s
        margin-top: 0

.imgContainer
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 50%
    margin: 0 40px
    height: 300px
    @include for-small-desktop
        width: 70%
        margin: 30px
        max-height: 300px
    @include for-tablet
        max-height: 250px
    // @include for-tablet-portrait
    //     display: none
    img
        width: 100%
        height: 100%
        object-fit: contain
    .elipsBlur
        border-radius: 50%
        position: absolute
        width: 230px
        height: 230px
        background-color: var(--accent-color-2)
        filter: blur(20px)
        opacity: 0.5
        @include for-small-desktop
            width: 200px
            height: 200px
        @include for-tablet
            width: 160px
            height: 160px

.btnsContainer
    display: flex
    width: 100%
    margin-bottom: 10px
    @include for-tablet-portrait
        flex-direction: column

.btnContainer
    margin: 30px 0 40px
    width: 100%
    max-width: 350px
    .outlineBtn
        font: var(--font-md20)
        display: flex
        align-items: center
        gap: 8px
        justify-content: center
        background: transparent
        box-shadow: none
        border: 1px solid var(--light-accent-color)
        transition: all 2s
    &:first-child
        margin-right: 40px
    @include for-small-desktop
        margin: 40px 0
        max-width: 300px
    @include for-tablet
        margin: 30px 0
        max-width: 250px
    @include for-tablet-portrait
        margin: 20px 0 0 0
        max-width: 300px
    @include for-phone
        margin: 20px 0 0 0
        max-width: 100%

.imgMobContainer
    display: none
    // @include for-tablet-portrait
    //     display: block
    //     position: relative
    //     width: 100%
    //     height: 200px
    //     img
    //         width: 100%
    //         height: 100%
    //         object-fit: contain

@media screen and (max-width: 950px)
    .imgContainer
        display: none
