@import '@styles/mixins.sass'

.container
    width: 100%
    form
        display: flex
        flex-direction: column
        p
            margin-bottom: 15px
            @include for-tablet-portrait
                margin-bottom: 10px
        input
            margin-bottom: 15px
            @include for-tablet-portrait
                margin-bottom: 10px

.goToRestore
    cursor: pointer
    margin-top: 15px
    @include for-tablet-portrait
        margin-top: 10px
    span
        font: var(--font-md18)
        line-height: 25px
        letter-spacing: -0.01em
        text-decoration-line: underline
        color: var(--accent-color-2)
        @include for-phone
            font: var(--font-md16)
            line-height: 20px

.descriptionContainer
    margin-bottom: 25px
    display: flex
    align-items: center
    justify-content: center
    p
        color: #6C6C6C
        font: var(--font-md18)
        