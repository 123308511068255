@import '@styles/mixins.sass'

.container
    min-height: 100vh
    height: 100%
    padding: 180px 140px 40px
    display: flex
    align-items: center
    justify-content: center
    @include for-small-desktop
        padding: 180px 100px 40px
    @include for-tablet
        padding: 190px 60px 40px
    @include for-tablet-portrait
        padding: 180px 40px 30px
    @include for-phone
        padding: 180px 20px 20px

.contentContainer
    background-color: var(--primary-color)
    width: 560px
    height: 335px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    @include for-tablet
        width: 510px
        height: 280px
    @include for-tablet-portrait
        width: 400px
        height: 230px
    @include for-phone
        width: 100%
        height: 230px

.headerContainer
    position: absolute
    z-index: 3
    top: -175px
    @include for-tablet
        top: -95px
    @include for-tablet-portrait
        top: -80px
    @include for-phone
        top: -95px
    h3
        text-transform: uppercase
        font: var(--font-lg)
        font-weight: 700
        max-width: 550px
        text-align: center
        color: var(--text-color)
        @include for-tablet
            font: var(--font-lg60)
            min-width: 700px
            font-weight: 700
        @include for-tablet-portrait
            font: var(--font-lg50)
            min-width: 500px
            font-weight: 700
        @include for-phone
            font: var(--font-lg38)
            min-width: 300px
            max-width: 320px
            font-weight: 700

.btnsContainer
    background-color: var(--primary-color)
    width: 100%
    height: 100%
    padding: 70px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: absolute
    z-index: 2
    @include for-tablet-portrait
        padding: 60px 50px 50px
    @include for-phone
        padding: 40px 20px 20px
    button
        margin: 30px 0 0 0
        @include for-tablet 
            margin: 25px 0 0 0
        @include for-tablet-portrait
            margin: 20px 0 0 0
        @include for-phone
            margin: 15px 0 0 0

.tickIconContainer
    width: 63px
    height: 63px
    @include for-tablet
        width: 50px
        height: 50px
    @include for-tablet-portrait
        width: 40px
        height: 40px
    @include for-phone
        width: 35px
        height: 35px

.mapMarkBlueContainer
    position: absolute
    z-index: 1
    top: 10%
    left: -250px
    @include for-tablet
        left: -200px
    @include for-tablet-portrait
        left: -120px
        top: 15%
    @include for-phone
        display: none
    div
        display: flex
        align-items: center
        justify-content: center
        svg
            width: 300px
            height: 70px
            @include for-tablet
                width: 250px
                height: 50px
            @include for-tablet-portrait
                width: 200px
                height: 40px
            @include for-phone
                width: 150px
                height: 30px

.mapMarkYellowContainer
    position: absolute
    z-index: 1
    right: -200px
    bottom: 10%
    @include for-tablet
        right: -150px
    @include for-tablet-portrait
        right: -100px
        bottom: 15%
    @include for-phone
        display: none
    div
        display: flex
        align-items: center
        justify-content: center
        svg
            width: 300px
            height: 70px
            @include for-tablet
                width: 250px
                height: 50px
            @include for-tablet-portrait
                width: 200px
                height: 40px
            @include for-phone
                width: 150px
                height: 30px
