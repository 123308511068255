@import '@styles/mixins.sass'

.container
    min-width: 600px
    @include for-small-desktop
        min-width: 540px
    @include for-tablet-portrait
        min-width: 500px
    @include for-phone
        min-width: auto
        width: 100%

.switchersBtnsContainer
    padding: 30px 50px 0
    border-bottom: 1px solid var(--secondary-color)
    display: flex
    align-items: flex-end
    justify-content: flex-start
    background-color: var(--primary-color)
    @include for-small-desktop
        padding: 20px 45px 0
    @include for-phone
        padding: 20px 20px 0

.switcherBtn
    display: flex
    align-items: flex-start
    border: 0
    border-bottom: 2px solid transparent
    outline: 0
    font-weight: 500
    font-size: 20px
    line-height: 24px
    text-align: center
    letter-spacing: -0.01em
    color: var(---text-color)
    padding: 13px 25px 13px 0
    background-color: var(--primary-color)
    transition: 0.3s
    color: var(--text-color)
    @include for-small-desktop
        font-size: 18px
        line-height: 22px
        padding: 10px 20px 10px 0
    @include for-phone
        font-size: 16px
        line-height: 20px
        min-width: auto
        width: 100%
    &:hover
        background-color: var(--primary-color)
        cursor: pointer

.switcherBtnActive
    @extend .switcherBtn
    border-bottom: 2px solid var(--accent-color)
    color: var(--accent-color)

.formContainer
    background-color: var(--primary-color)
    padding: 30px 50px
    @include for-small-desktop
        padding: 25px 45px
    @include for-tablet
        padding: 25px 40px
    @include for-phone
        padding: 20px 20px
