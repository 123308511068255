@import '@styles/mixins.sass'

.container
    position: fixed
    min-height: 100vh
    min-height: 100svh
    height: 100%
    width: 100%
    top: 0
    left: 0
    z-index: 1000
    animation: appearing .3s ease-in-out
    backdrop-filter: blur(5px)
    background-color: var(--inverted-bg-color-transparent)
    display: flex
    justify-content: center
    align-items: center
    transition: opacity 0.3s ease-in-out

.contentContainer
    position: relative
    display: flex
    flex-direction: column
    animation: appearing-content .3s ease-in-out
    opacity: 1
    background-color: var(--primary-color)
    min-width: 300px
    width: 100%
    max-width: 2200px
    margin: 0 140px
    padding: 100px 60px 30px 60px
    box-shadow: 0 0 10px 0 var(--bg-color-blur)
    @include for-tablet
        margin: 0 100px
        padding: 100px 40px 30px 40px
        max-height: 95vh
    @include for-tablet-portrait
        margin: 10px 60px
        padding: 100px 30px 30px 30px
    @include for-phone
        margin: 20px 15px
        padding: 90px 20px 30px 20px
        max-height: 80vh

.title
    width: calc( 100% - 170px )
    position: absolute
    top: 50px
    left: 60px
    color: var(--text-color)
    text-align: center
    @include for-phone
        top: 20px

.titleContainer
    width: calc( 100% - 170px )
    position: absolute
    top: 50px
    left: 60px
    display: flex
    align-items: center
    justify-content: center !important
    p
        color: var(--text-color)
        text-align: center
        font: var(--font-md22)
        font-weight: 700
        span
            color: var(--accent-color-2)
    @include for-phone
        top: 20px

.closeIconContainer
    width: 30px
    height: 30px
    position: absolute
    top: 50px
    right: 60px
    cursor: pointer
    transition: transform 0.3s ease-in-out
    border-radius: 25px
    padding: 5px
    @include for-tablet
        top: 40px
        right: 40px
    @include for-phone
        top: 20px
        right: 20px
    &:hover
        transform: rotate(90deg)

.title
    margin-right: 50px

.childrenContainer
    width: 100%
    max-height: 80vh
    overflow-x: auto

.yellow
    span
        color: var(--accent-color)
    
.blue
    span
        color: var(--accent-color-2)

@keyframes appearing
    0%
        backdrop-filter: blur(0px)
        background-color: transparent
    100%
        backdrop-filter: blur(5px)
        background-color: var(--inverted-bg-color-transparent)

@keyframes appearing-content
    0%
        opacity: 0
    100%
        opacity: 1
        
