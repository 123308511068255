@import '@styles/colors.sass'
@import '@styles/mixins.sass'

.container
    min-height: 100vh
    height: 100%
    padding: 120px 140px 40px
    display: flex
    align-items: center
    justify-content: center
    @include for-small-desktop
        padding: 160px 70px 60px
        align-items: flex-start
    @include for-tablet
        padding: 120px 60px 60px
        align-items: center
    @include for-tablet-portrait
        padding: 130px 40px 60px
    @include for-phone
        padding: 90px 20px 60px

.contentContainer
    display: flex
    align-items: flex-start
    justify-content: space-between
    width: 100%
    @include for-tablet
        flex-direction: column
        align-items: center
        justify-content: center

.leftContainer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 50%
    margin-right: 50px
    @include for-tablet
        width: 100%
        flex-direction: row
        margin-right: 0

.headerContainer
    width: 100%
    h1
        max-width: 700px
        @include for-small-desktop
            max-width: 500px
        @include for-tablet
            max-width: 100%
            margin-right: 40px
        @include for-tablet-portrait
            margin-right: 0
            text-align: center

.imgContainer
    margin-top: 50px
    width: 420px
    height: 420px
    position: relative
    @include for-small-desktop
        width: 390px
        height: 390px
    @include for-tablet
        margin-top: 0
        height: 280px
        width: 500px
    @include for-tablet-portrait
        display: none
    img
        object-fit: contain

.loginSwitcherContainer
    @include for-tablet
        margin-top: 50px
    @include for-tablet-portrait
        display: flex
        flex-direction: column-reverse
        align-items: center
        justify-content: center
    @include for-phone
        width: 100%
        margin-top: 30px

.imgContainerTablet
    display: none
    @include for-tablet-portrait
        display: block
        margin-top: 50px
        width: 350px
        height: 350px
        position: relative
        @include for-phone
            width: 300px
            height: 300px
            margin-top: 30px
        img
            object-fit: contain


